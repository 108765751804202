/* You can add global styles to this file, and also import other style files */
@import 'constants';
@import 'shared-styles';

:root {
  --colorPrimaryRgb: 1, 169, 202;
  --colorPrimary: rgb(var(--colorPrimaryRgb));

  // putting it outside won't work
  .magic-button-entry {
    width: auto;
  }

  .celum-avatar_name {
    margin-left: 0.6rem;
    font-weight: 600;
    color: $color-blue-gray-900;
  }
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-disabled) {
  color: $color-blue-gray-900 !important;
}

.snackbar-wrapper {
  flex-direction: column;
  position: fixed;
  z-index: 10000000;
  bottom: 2rem;
  left: 8rem;
  filter: none !important;
  transition:
    width 0.5s,
    left 0.5s;
  min-width: 36rem;
}

.mat-mdc-menu-panel {
  scrollbar-color: rgba($color-blue-gray-400, 0.5) $color-blue-gray-050;
  scrollbar-width: thin;
}

.mat-mdc-menu-content {
  .mat-mdc-menu-item {
    height: 3.2rem;
    line-height: 3.2rem;
  }
}

.mat-mdc-chip-action-label {
  @include chip();
}

// Styles to mimic the scrollbar for chrome
::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-track:hover {
  background-color: $color-blue-gray-050;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($color-blue-gray-400, 0.5);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: fade($color-blue-gray-400, 0.5);
}

// fix for doubled tooltips in safari
[spaceAwareTooltip]::after,
[spaceAwareTooltip] > *::after {
  content: '';
  display: block;
}
